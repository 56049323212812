import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=615fd5e0&scoped=true"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=615fd5e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615fd5e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/vercel/path0/components/general/Logo.vue').default,LogoWithoutText: require('/vercel/path0/components/general/LogoWithoutText.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,SearchIcon: require('/vercel/path0/components/icons/SearchIcon.vue').default,LangSwitcher: require('/vercel/path0/components/general/LangSwitcher.vue').default,AppButton: require('/vercel/path0/components/general/AppButton.vue').default,MobileMenuButton: require('/vercel/path0/components/general/navigation/MobileMenuButton.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,MobileMenu: require('/vercel/path0/components/general/navigation/MobileMenu.vue').default})
